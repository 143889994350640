:root {
  --bg-1: #dbdbdb;
  --bg-accent: #d1d1d1;
  --flavor-text: #a2d9fd;
  --links: #636363;
  --background: #4c4c4c;
}

* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
}

.border {
  border: 1px solid white;
}

section {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-between;
}

section:nth-child(even) {
  background-color: var(--bg-accent);
  color: #6c6c6c;
}

section:nth-child(odd) {
  color: var(--flavor-text);
  background-color: var(--links);
}

section .content {
  margin: 0 50px;
  padding-top: 50px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

p,
span,
li {
  font-size: 18px;
}

img {
  width: 90%;
  max-width: 900px;
}

li {
  list-style: none;
  margin-left: 10px;
}

a {
  color: var(--flavor-text);
  text-decoration: none;
}

figure {
  text-align: center;
}

.article {
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  margin: 30px 10px;
}

.article-list {
  margin: 0 5px 20px 5px;
  line-height: 3em;
  max-width: 100%;
}

.article-list li {
  line-height: 2em;
}

.btn {
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 10px;
  border: 2px solid #a2d9fd;
  transition: 0.5s ease-in-out;
}

.live-btn {
  background-color: #a2d9fd;
  color: rgb(27, 38, 48);
}

.source-btn {
  color: #a2d9fd;
}

.btn:focus {
  border: 3px solid black;
}

.btn:hover {
  cursor: pointer;
  box-shadow: 0 0 10px;
}

.live-btn:hover {
  background-color: transparent;
  color: #a2d9fd;
}

.source-btn:hover {
  background-color: #a2d9fd;
  color: rgb(27, 38, 48);
}

.btn:active {
  background-color: var(--links);
  color: var(--flavor-text);
  border: 2px solid var(--bg-accent);
}

/* Header */

header {
  z-index: 1;
  width: 100%;
  background-color: rgb(24 24 24 / .8);
  position: fixed;
  backdrop-filter: blur(5px);
}

.navbar {
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.nav-branding {
  font-size: 2rem;
}

.nav-link {
  transition: 0.7s ease;
}

.nav-link:hover {
  color: white;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: var(--flavor-text);
  border-radius: 3px;
}

@media screen and (max-width: 680px) {
  .hamburger {
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu {
    position: fixed;
    right: -100%;
    top: 50px;
    gap: 0;
    flex-direction: column;
    background-color: var(--background);
    width: 100%;
    text-align: center;
    transition: 0.3s;
  }

  .nav-item {
    margin: 16px 0;
  }

  .nav-menu.active {
    right: 0;
  }
}

/* Page Hero */

@media screen and (max-width: 680px) {
  .page-hero {
    text-align: center;
  }
}

/* Projects */

.projects {
  min-height: 80vh;
}

.project {
  background-color: #484848;
  border-radius: 10px;
  padding: 15px;
  margin: 40px auto;
  transition: 0.5s ease;
  align-items: start;
  overflow: hidden;
  width: 90%;
}

.project:hover {
  box-shadow: 0 0 10px 5px var(--flavor-text);
}

.project-description {
  font-style: italic;
  opacity: 0.7;
  font-size: 1rem;
  margin: 30px 0;
}

.project-image {
  margin: 50px 0;
  width: 90%;
  max-width: 900px;
  border-radius: 5px;
}

.projects span {
  font-weight: 600;
}

.projects ul {
  margin: 30px 0;
}

.project-links {
  display: flex;
  align-self: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 20px;
}

.project-links .btn {
  width: 150px;
  height: 50px;
}

/* Skills */

.skills {
  min-height: fit-content;
}

.skills-list {
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between;
  gap: 30px;
  margin-bottom: 50px;
}

.skills h2 {
  margin: 50px;
}
.skill-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  font-weight: 500;
  font-size: 24px;
  background-color: var(--links);
  color: var(--flavor-text);
  position: relative;
  top: 0;
  transition: 0.5s ease;
  border-radius: 10px;
}

.skills .skill-item:hover {
  box-shadow: 0 10px 10px 5px #8d8d8d;
  top: -10px;
  padding: 10px;
}

/* Contact Section */

.form-content {
  width: 80%;
  max-width: 920px;
}

.form-control {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--flavor-text);
  font-size: 18px;
  margin: 40px auto;
  overflow: hidden;
  line-height: 2em;
  color: var(--flavor-text);
  font-family: Helvetica, monospace;
}

.form-control:not(button) {
  box-shadow: inset 0 0 3px black;
  -webkit-box-shadow: inset 0 0 3px black;
  border-radius: unset;
  padding: 5px;
}

.form-control::placeholder,
textarea.form-control::placeholder {
  color: var(--flavor-text);
  font-family: Helvetica, monospace;
  position: relative;
  left: 0;
  transition: left 0.4s ease, color 0.4s ease;
  -webkit-transition: left 0.4s ease, color 0.4s ease;
  -moz-transition: left 0.4s ease, color 0.4s ease;
}

.form-control:focus::placeholder {
  position: relative;
  color: transparent;
  left: 60%;
}

button.submit-btn {
  height: 50px;
  margin-top: 40px;
  background-color: var(--bg-1);
  color: var(--links);
  border: none;
  outline: none;
}

.honey {
  display: none;
}

.social-links {
  display: flex;
  justify-content: center;
  width: 3em;
  height: 3em;
  margin: 8px;
  gap: 28px;
}
.social-links svg {
  height: 3em;
  width: 3em;
  transition: 0.5s ease;
}

.social-links svg:hover {
  transform: translateY(-16px);
  padding-bottom: 16px
}